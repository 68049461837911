import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import PortableText from '../components/portable-text/portable-text';
import Lede from '../components/lede/lede';
import Hero from '../components/hero/hero';
import CenterColumn from '../components/center-column/center-column';
import { Card, CardBody, CardFooter, Image, Text } from 'grommet';

import { buildImageObj } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';
import { Checkmark } from 'grommet-icons';
import Marker from '../components/marker/marker';

export const query = graphql`
  query ReviewTemplateQuery($id: String!) {
    review: sanityReview(id: { eq: $id }) {
      id
      title
      game {
        title
        releaseDate
        mainImage {
          ...Image
        }
      }
      lede
      recommended
      reason
      _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        ...CaptionedImage
      }
      slug {
        current
      }
    }
  }
`;

const ReivewPage = ({ data, errors, pageContext }) => {
  const { lede, _rawBody, game, recommended, mainImage, reason } = data.review;

  return (
    <Layout>
      <Hero mainImage={mainImage} title={game.title}>
        <Marker text="Review" />
      </Hero>
      <Lede text={lede} />
      <CenterColumn>
        <Card>
          <CardBody>
            <Image
              src={imageUrlFor(buildImageObj(game.mainImage))
                .width(800)
                .height(Math.floor((9 / 16) * 450))
                .fit('crop')
                .auto('format')
                .url()}
              a11yTitle={game.title}
            />
          </CardBody>
          <CardFooter pad="small" justify="start">
            <Text weight="bold">Recommended: </Text>
            {recommended ? <Checkmark color="accent-1" /> : <></>}

            <Text weight="bold">Reason: </Text>
            <Text>{reason}</Text>
          </CardFooter>
        </Card>
        {_rawBody && <PortableText blocks={_rawBody} />}
      </CenterColumn>
    </Layout>
  );
};

export default ReivewPage;
